<template>
  <div class="relative w-screen h-auto">
    <div v-if="!isMobile" class="fixed top-0 left-0 w-full h-[4vw] z-50 flex justify-between">
      <div class="w-[80%] h-full bg-[#F40000] box-shadow flex">
        <div @click="scrollToElement(banner, 'start')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">HOME</h1>
        </div>
        <div @click="scrollToElement(banner, 'center')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">ABOUT</h1>
        </div>
        <div @click="scrollToElement(swap, 'start')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">SWAP</h1>
        </div>
        <div @click="scrollToElement(staking, 'start')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">STAKING</h1>
        </div>
        <div @click="scrollToElement(roadmap, 'start')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">ROADMAP</h1>
        </div>
        <div @click="scrollToElement(partner, 'start')"
          class="flex-1 h-full flex justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#146AB0]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">PARTNER</h1>
        </div>
      </div>
      <div class="w-[18%] h-full bg-[#003D70] box-shadow">
        <div @click="connectWallet()"
          class="flex-1 h-full flex gap-[1vw] justify-center items-center cursor-pointer transition-all duration-300 hover:bg-[#F40000]">
          <h1 class="lalezar-regular text-[2vw] text-[#FFFFFF] leading-none pt-[0.5vw]">{{ !walletAddress ?
            'CONNECT WALLET' : shortenAddress(walletAddress) }}</h1>
          <div v-if="walletAddress" class="h-full w-auto flex justify-center items-center">
            <img src="./assets/disconnect-wallet-ic.png"
              class="h-1/2 w-auto transition-all duration-300 hover:scale-110 active:scale-95">
          </div>
        </div>
      </div>
    </div>
    <div v-else class="fixed top-0 left-0 w-full h-[15vw] z-50 flex justify-between">
      <div class="h-full w-[calc(100%-15vw)] bg-[#001E36] flex justify-start items-center">
        <h1 class="lalezar-regular text-[13.5vw] text-[#FFFFFF] leading-none pt-[2.5vw] pl-[3vw]">TRUMPSWAP</h1>
      </div>
      <div class="h-full w-[15vw] bg-[#F00] flex justify-center items-center">
        <img src="./assets/mobile-menu-ic.png" class="w-[65%] h-auto">
      </div>
    </div>
    <div v-if="!isMobile" class="absolute top-0 left-0 w-full h-auto overflow-hidden">
      <div ref="banner" class="w-full h-auto pointer-events-none">
        <img src="./assets/banner.webp" class="w-full h-auto">
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/listed.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center">
          <div class="h-full w-[80%] flex flex-col gap-[5vw] justify-center items-start">
            <div class="h-auto w-full flex">
              <img src="./assets/listed-title.webp" class="w-1/2 h-auto">
              <div class="h-full w-1/2 flex gap-[2vw] justify-end items-end">
                <div
                  class="relative h-1/2 w-[30%] cursor-pointer transition-all duration-300 hover:scale-110 mr-[0.5vw] border-2 border-black rounded-lg">
                  <div
                    class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                  </div>
                  <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                    <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                      <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
                    </div>
                    <div class="h-full w-2/3 flex justify-center items-center">
                      <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">PRESALE</h1>
                    </div>
                  </div>
                </div>
                <div
                  class="relative h-1/2 w-[30%] cursor-pointer transition-all duration-300 hover:scale-110 mr-[0.5vw] border-2 border-black rounded-lg">
                  <div
                    class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                  </div>
                  <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                    <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                      <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
                    </div>
                    <div class="h-full w-2/3 flex justify-center items-center">
                      <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">AUDIT</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full h-auto flex justify-between">
              <div class="relative w-[48%] h-auto">
                <img src="./assets/listed-cex.webp" class="w-full h-auto">
                <div
                  class="absolute top-0 right-[0.7%] h-full w-[89%] flex flex-col gap-[10%] justify-center items-center">
                  <div class="h-1/3 w-[80%] flex justify-between">
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                  </div>
                  <div class="h-1/3 w-[80%] flex justify-between">
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div class="relative w-[48%] h-auto">
                <img src="./assets/listed-dex.webp" class="w-full h-auto">
                <div
                  class="absolute top-0 right-[0.7%] h-full w-[89%] flex flex-col gap-[10%] justify-center items-center">
                  <div class="h-1/3 w-[80%] flex justify-between">
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                  </div>
                  <div class="h-1/3 w-[80%] flex justify-between">
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                    <div class="h-full w-[30%] flex flex-col justify-between items-center">
                      <div class="w-[60%] h-auto">
                        <img src="./assets/listed-binance.webp"
                          class="w-full h-auto transition-all duration-300 hover:scale-110">
                      </div>
                      <h1 class="lalezar-regular text-[1.2vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="swap" class="relative w-full h-auto">
        <img src="./assets/swap.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 h-[92%] w-2/3 flex justify-end items-center">
          <div class="h-[35vw] w-[87%] mr-[2%] rounded-xl overflow-hidden">
            <div id="dexscreener-embed">
              <iframe ref="chart"
                src="https://dexscreener.com/solana/So11111111111111111111111111111111111111112?embed=1&theme=dark&trades=1&info=0"></iframe>
            </div>
          </div>
        </div>
        <div class="absolute top-0 right-0 h-[92%] w-1/3 flex justify-start items-center">
          <div class="h-[35vw] w-[75%] bg-[#257DDD] rounded-xl ml-[2%] flex justify-center">
            <div class="w-[90%] h-full flex flex-col">
              <div class="w-full h-auto flex justify-between mt-[1.5vw]">
                <div class="w-auto h-auto">
                  <h1 class="lalezar-regular text-[2vw] text-white leading-none">Swap</h1>
                </div>
                <div class="w-auto h-full flex gap-[0.7vw] justify-center items-center">
                  <h1 class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw]">0.3%</h1>
                  <img src="./assets/swap-setting-ic.png"
                    class="h-[1.3vw] w-auto transition-all hover:scale-110 active:scale-95">
                </div>
              </div>
              <div class="relative w-full h-[3vw] mt-[2vw]">
                <input v-model="searchAddress" type="text" placeholder="Seach address contract" class="w-full h-full rounded-full bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[1.3vw] pt-[0.3vw] pl-[5%] pr-[12%]">
                <div class="absolute top-0 right-0 h-full aspect-square flex justify-center items-center">
                  <img @click="showChart()" src="./assets/swap-search-ic.webp"
                    class="h-[50%] w-auto transition-all hover:scale-110 active:scale-95">
                </div>
              </div>
              <div class="relative w-full h-[1.5vw] mt-[1vw] mb-[1vw] flex justify-between">
                <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.25vw]">
                  Balance: 0</h1>
                <div
                  class="h-full w-auto bg-white pl-[3%] pr-[3%] rounded-full transition-all duration-300 hover:scale-110 active:scale-95 cursor-pointer">
                  <h1 class="lalezar-regular text-[1vw] text-[#257DDD] leading-none pt-[0.35vw]">
                    MAX</h1>
                </div>
              </div>

              <div class="relative w-full h-[3vw] bg-white rounded-xl flex justify-center">
                <div class="h-full w-[90%] flex justify-between">
                  <div class="h-full w-[48%] flex gap-[0.5vw] justify-start items-center">
                    <img :src="listTokenSolRaw ? listTokenSolRaw[0].logoURI : ''" class="h-1/2 w-auto rounded-full">
                    <h1 class="lalezar-regular text-[1.6vw] text-[#257DDD] leading-none pt-[0.4vw]">
                      {{ listTokenSolRaw ? listTokenSolRaw[0].symbol : '' }}</h1>
                    <img src="./assets/swap-arrow.png" @click="showSelectToken('top')"
                      class="h-2/3 w-auto transition-all duration-300 hover:scale-125 active:scale-95">
                  </div>
                  <div class="w-[48%] h-full">
                    <input type="text" placeholder="0.00" class="w-full h-full rounded-full border-none outline-none text-[#257DDD] 
                    lalezar-regular text-[1.6vw] pt-[0.35vw]" style="direction: rtl; caret-color: transparent;">
                  </div>
                </div>
              </div>

              <div class="relative w-full h-[1.5vw] mt-[1vw] mb-[1vw] flex items-center justify-center">
                <hr class="w-[90%]">
                <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                  <img src="./assets/swap-swap-ic.webp"
                    class="h-full w-auto transition-all hover:scale-110 active:scale-95">
                </div>
              </div>

              <div class="relative w-full h-[3vw] bg-white rounded-xl flex justify-center">
                <div class="h-full w-[90%] flex justify-between">
                  <div class="h-full w-[48%] flex gap-[0.5vw] justify-start items-center">
                    <img :src="listTokenSolRaw ? listTokenSolRaw[1].logoURI : ''" class="h-1/2 w-auto rounded-full">
                    <h1 class="lalezar-regular text-[1.6vw] text-[#257DDD] leading-none pt-[0.4vw]">
                      {{ listTokenSolRaw ? listTokenSolRaw[1].symbol : '' }}</h1>
                    <img src="./assets/swap-arrow.png" @click="showSelectToken('bottom')"
                      class="h-2/3 w-auto transition-all duration-300 hover:scale-125 active:scale-95">
                  </div>
                  <div class="w-[48%] h-full">
                    <input type="text" placeholder="0.00" class="w-full h-full rounded-full border-none outline-none text-[#257DDD] 
                    lalezar-regular text-[1.6vw] pt-[0.35vw]" style="direction: rtl; caret-color: transparent;">
                  </div>
                </div>
              </div>

              <div class="w-full h-[1.5vw] flex justify-end items-center">
                <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.25vw] mt-[1vw]">
                  {{ rate }}</h1>
              </div>
              <div
                class="relative w-full h-[3vw] bg-[#60adff] rounded-full mt-[6vw] border-2 border-white flex justify-center items-center transition-all duration-300 cursor-pointer swap-btn active:scale-105">
                <h1 @click="!walletAddress ? connectWallet() : swapToken()"
                  class="lalezar-regular text-[1.5vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                  {{ !walletAddress ? 'Connect wallet' : 'Swap' }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref="staking" class="relative w-full h-auto">
        <img src="./assets/staking.webp" class="w-full h-auto">
        <div class="absolute top-0 right-0 h-full w-1/3 flex justify-start items-center">
          <div class="h-[35vw] w-[75%] rounded-xl ml-[2%] flex flex-col justify-between">
            <div class="w-full h-[10%] flex">
              <div ref="stakingBtnToggle" @click="stakingWithdrawToggle(1)"
                class="h-full w-1/2 bg-[#257DDD] rounded-xl flex justify-center items-center cursor-pointer transition-all duration-300">
                <h1
                  class="lalezar-regular text-[2vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                  Staking</h1>
              </div>
              <div ref="withdrawBtnToggle" @click="stakingWithdrawToggle(2)"
                class="h-full w-1/2 rounded-xl flex justify-center items-center cursor-pointer transition-all duration-300">
                <h1
                  class="lalezar-regular text-[2vw] text-[#257DDD] leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                  Withdraw</h1>
              </div>
            </div>

            <div ref="stakingFrame"
              class="w-full h-[87%] bg-[#257DDD] rounded-xl flex justify-center transition-all duration-300">
              <div class="w-[90%] h-full flex flex-col">
                <div class="w-full h-auto flex justify-between mt-[1.5vw]">
                  <div class="w-auto h-auto">
                    <h1 class="lalezar-regular text-[2vw] text-white leading-none">Staking</h1>
                  </div>
                  <div class="w-auto h-full flex gap-[0.2vw] justify-center items-center">
                    <img src="./assets/token.webp" class="h-[1.3vw] w-auto">
                    <h1 class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw]">$TRS</h1>
                  </div>
                </div>
                <div class="relative w-full h-[1.5vw] mt-[1.5vw] mb-[0.5vw] flex justify-start">
                  <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.25vw]">
                    Balance: 0</h1>
                </div>
                <div class="relative w-full h-[3vw]">
                  <input type="text" placeholder="0.00" class="w-full h-full rounded-xl bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[1.3vw] pt-[0.3vw] pl-[5%] pr-[20%]">
                  <div class="absolute top-0 right-0 w-auto h-full flex justify-center items-center">
                    <div
                      class="h-[80%] w-auto bg-[#257DDD] pl-[1vw] pr-[1vw] rounded-xl transition-all duration-300 hover:scale-110 active:scale-95 cursor-pointer flex justify-center items-center mr-[0.3vw]">
                      <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.3vw]">
                        MAX</h1>
                    </div>
                  </div>
                </div>
                <div class="relative w-full h-[1.5vw] mt-[1vw] mb-[1vw] flex justify-between items-center">
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 staking-month-btn cursor-pointer">
                    <h1
                      class="lalezar-regular text-[0.7vw] text-white leading-none pt-[0.2vw] transition-all duration-300 staking-month-btn-text">
                      1 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 staking-month-btn cursor-pointer">
                    <h1
                      class="lalezar-regular text-[0.7vw] text-white leading-none pt-[0.2vw] transition-all duration-300 staking-month-btn-text">
                      3 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 staking-month-btn cursor-pointer">
                    <h1
                      class="lalezar-regular text-[0.7vw] text-white leading-none pt-[0.2vw] transition-all duration-300 staking-month-btn-text">
                      6 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 staking-month-btn cursor-pointer">
                    <h1
                      class="lalezar-regular text-[0.7vw] text-white leading-none pt-[0.2vw] transition-all duration-300 staking-month-btn-text">
                      12 Month</h1>
                  </div>
                </div>
                <div
                  class="relative w-full h-[3vw] bg-[#60adff] rounded-full border-2 border-white flex justify-center items-center transition-all duration-300 cursor-pointer swap-btn active:scale-105">
                  <h1
                    class="lalezar-regular text-[1.5vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    Connect wallet</h1>
                </div>
                <div class="relative w-full h-[1.5vw] mt-[1vw] mb-[1vw] flex items-center justify-center">
                  <hr class="w-full">
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    Apy</h1>
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    308%</h1>
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    Staking Amount</h1>
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    0 $TRS</h1>
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    Profit</h1>
                  <h1
                    class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    0 $TRS</h1>
                </div>
                <div
                  class="relative w-full h-[3vw] mt-[1vw] bg-[#60adff] rounded-full border-2 border-white flex justify-center items-center transition-all duration-300 cursor-pointer swap-btn active:scale-105">
                  <h1
                    class="lalezar-regular text-[1.5vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                    Connect wallet</h1>
                </div>
              </div>
            </div>

            <div ref="withdrawFrame"
              class="w-full h-[87%] flex justify-center items-start transition-all duration-300 opacity-0 hidden">
              <div class="h-[70%] w-full bg-[#257DDD] rounded-xl flex justify-center">
                <div class="h-full w-[90%] flex flex-col">
                  <div class="w-full h-auto flex justify-between mt-[1.5vw]">
                    <div class="w-auto h-auto">
                      <h1 class="lalezar-regular text-[2vw] text-white leading-none">Withdraw</h1>
                    </div>
                    <div class="w-auto h-full flex gap-[0.2vw] justify-center items-center">
                      <img src="./assets/token.webp" class="h-[1.3vw] w-auto">
                      <h1 class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw]">$TRS</h1>
                    </div>
                  </div>
                  <div class="relative w-full h-[1.5vw] mt-[1.5vw] mb-[0.5vw] flex justify-start">
                    <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.25vw]">
                      Balance: 0</h1>
                  </div>
                  <div class="relative w-full h-[3vw]">
                    <input type="text" placeholder="0.00" class="w-full h-full rounded-xl bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[1.3vw] pt-[0.3vw] pl-[5%] pr-[20%]">
                    <div class="absolute top-0 right-0 w-auto h-full flex justify-center items-center">
                      <div
                        class="h-[80%] w-auto bg-[#257DDD] pl-[1vw] pr-[1vw] rounded-xl transition-all duration-300 hover:scale-110 active:scale-95 cursor-pointer flex justify-center items-center mr-[0.3vw]">
                        <h1 class="lalezar-regular text-[1vw] text-white leading-none pt-[0.3vw]">
                          MAX</h1>
                      </div>
                    </div>
                  </div>
                  <div class="h-auto w-auto flex justify-between mt-[1vw]">
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      Apy</h1>
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      308%</h1>
                  </div>
                  <div class="h-auto w-auto flex justify-between">
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      Staking Amount</h1>
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      0 $TRS</h1>
                  </div>
                  <div class="h-auto w-auto flex justify-between">
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      Profit</h1>
                    <h1
                      class="lalezar-regular text-[1.3vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      0 $TRS</h1>
                  </div>
                  <div
                    class="relative w-full h-[3vw] mt-[1vw] bg-[#60adff] rounded-full border-2 border-white flex justify-center items-center transition-all duration-300 cursor-pointer swap-btn active:scale-105">
                    <h1
                      class="lalezar-regular text-[1.5vw] text-white leading-none pt-[0.4vw] swap-btn-text transition-all duration-300">
                      Connect wallet</h1>
                  </div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div ref="roadmap" class="relative w-full h-auto">
        <img src="./assets/roadmap-bg.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end roadmap-hover">
          <div class="relative w-[85%] h-[22vw] mb-[3vw] flex justify-between">
            <div class="h-full w-auto">
              <img src="./assets/roadmap-p1.webp" class="h-full w-auto">
            </div>
            <div class="h-full w-auto">
              <img src="./assets/roadmap-p2.webp" class="h-full w-auto">
            </div>
            <div class="h-full w-auto">
              <img src="./assets/roadmap-p3.webp" class="h-full w-auto">
            </div>
            <div class="h-full w-auto">
              <img src="./assets/roadmap-p4.webp" class="h-full w-auto">
            </div>
            <div class="absolute bottom-full left-0 w-auto h-full">
              <img src="./assets/roadmap-flag.webp" class="h-full w-auto translate-y-[5%]">
            </div>
            <div class="absolute top-0 left-0 w-full h-full flex justify-center items-end">
              <div ref="c1" class="h-full w-1/4 transition-all duration-300">
                <img src="./assets/roadmap-cloud-1.webp" class="h-auto w-full scale-90 origin-left -translate-y-[80%]">
              </div>
              <div ref="c2" class="h-full w-1/4 transition-all duration-300">
                <img src="./assets/roadmap-cloud-2.webp" class="h-auto w-full scale-90">
              </div>
              <div ref="c3" class="h-full w-1/4 transition-all duration-300">
                <img src="./assets/roadmap-cloud-3.webp" class="h-auto w-full scale-90">
              </div>
              <div ref="c4" class="h-full w-1/4 transition-all duration-300">
                <img src="./assets/roadmap-cloud-4.webp" class="h-auto w-full scale-90 origin-right">
              </div>
            </div>
            <div class="absolute bottom-full left-0 w-full h-auto flex justify-start items-start">
              <div ref="plane" class="h-auto w-1/4 plane transition-all duration-300">
                <img src="./assets/roadmap-plane.webp" class="h-auto w-full -translate-y-[20%] -translate-x-[10%]">
              </div>
            </div>
          </div>
        </div>
        <div class="absolute top-[10%] left-0 w-full h-auto flex justify-center">
          <img src="./assets/roadmap-title.webp" class="h-auto w-[50%]">
        </div>
        <div class="absolute top-0 left-0 w-full h-full flex">
          <div ref="p1" class="h-full w-1/4"></div>
          <div ref="p2" class="h-full w-1/4"></div>
          <div ref="p3" class="h-full w-1/4"></div>
          <div ref="p4" class="h-full w-1/4"></div>
        </div>
      </div>
      <div ref="partner" class="relative w-full h-auto">
        <img src="./assets/partner.webp" class="w-full h-auto">
        <div class="absolute top-0 right-0 h-full w-1/2 flex flex-col gap-[2vw] justify-center items-start">
          <img src="./assets/partner-title.webp" class="w-[70%] h-auto">
          <div class="w-[85%] h-[4vw] mt-[2vw] flex justify-between">
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">PINKSALE</h1>
                </div>
              </div>
            </div>
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-dexview.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">DEXVIEW</h1>
                </div>
              </div>
            </div>
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-raydium.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">RAYDIUM</h1>
                </div>
              </div>
            </div>
          </div>
          <div class="w-[85%] h-[4vw] flex justify-between">
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-cmk.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">COINGECKO</h1>
                </div>
              </div>
            </div>
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-cmc.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">COIN MC</h1>
                </div>
              </div>
            </div>
            <div class="relative h-full w-[30%] cursor-pointer transition-all duration-300 hover:scale-110">
              <div
                class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
              </div>
              <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                  <img src="./assets/partner-dextools.webp" class="h-[80%] w-auto">
                </div>
                <div class="h-full w-2/3 flex justify-center items-center">
                  <h1 class="lalezar-regular text-[1.5vw] text-[#257DDD] leading-none pt-[0.5vw]">DEXTOOLS</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/footer.webp" class="w-full h-auto">
        <div class="absolute bottom-0 left-0 w-full h-[3.25vw] flex justify-center">
          <div class="w-[93%] h-full flex justify-between items-center">
            <div class="h-auto w-auto">
              <h1 class="lalezar-regular text-[1vw] text-[#FFFFFF] leading-none pt-[0.5vw]">2024 Trump Swap . © All
                rights
                reserved </h1>
            </div>
            <div class="h-auto w-auto flex gap-[2vw]">
              <h1
                class="lalezar-regular text-[1vw] text-[#FFFFFF] leading-none pt-[0.5vw] cursor-pointer hover:underline">
                Terms of Service</h1>
              <h1
                class="lalezar-regular text-[1vw] text-[#FFFFFF] leading-none pt-[0.5vw] cursor-pointer hover:underline">
                Privacy Policy</h1>
            </div>
          </div>
        </div>
        <div class="absolute bottom-[4vw] left-0 w-full h-[3vw] flex justify-end">
          <div class="h-full w-auto flex gap-[2vw]">
            <img src="./assets/footer-telegram.webp" class="h-full w-auto transition-all duration-300 hover:scale-125">
            <img src="./assets/footer-telegram.webp" class="h-full w-auto transition-all duration-300 hover:scale-125">
            <img src="./assets/footer-x.webp"
              class="h-full w-auto mr-[5vw] transition-all duration-300 hover:scale-125">
          </div>
        </div>
      </div>
    </div>

    <div v-else class="absolute top-0 left-0 w-full h-auto overflow-hidden">
      <div ref="mobileBanner" class="w-full h-auto pointer-events-none">
        <img src="./assets/mobile-banner.webp" class="w-full h-auto">
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-listed.webp" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-[62.5%] flex justify-center">
          <div class="h-full w-[95%] flex flex-col gap-[7vw] justify-center items-center">
            <img src="./assets/mobile-listed-title.webp" class="w-full h-auto ">
            <div class="relative w-full h-auto">
              <img src="./assets/listed-cex.webp" class="w-full h-auto">
              <div
                class="absolute top-0 right-[0.7%] h-full w-[89%] flex flex-col gap-[10%] justify-center items-center">
                <div class="h-1/3 w-[80%] flex justify-between">
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                </div>
                <div class="h-1/3 w-[80%] flex justify-between">
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative w-full h-auto">
              <img src="./assets/listed-dex.webp" class="w-full h-auto">
              <div
                class="absolute top-0 right-[0.7%] h-full w-[89%] flex flex-col gap-[10%] justify-center items-center">
                <div class="h-1/3 w-[80%] flex justify-between">
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                </div>
                <div class="h-1/3 w-[80%] flex justify-between">
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                  <div class="h-full w-[30%] flex flex-col justify-between items-center">
                    <div class="w-[60%] h-auto">
                      <img src="./assets/listed-binance.webp" class="w-full h-auto">
                    </div>
                    <h1 class="lalezar-regular text-[3vw] text-[#257DDD] leading-none pt-[0.5vw]">Binance</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="absolute bottom-0 left-0 w-full h-[33.5%] flex flex-col gap-[7vw] justify-center items-center">
          <div class="relative h-[20%] w-[95%] cursor-pointer mr-[0.5vw] border-2 border-black rounded-lg">
            <div class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[1vw] translate-y-[1vw]">
            </div>
            <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
              <div class="h-full w-1/5 bg-[#D9D9D9] flex justify-center items-center">
                <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
              </div>
              <div class="h-full w-4/5 flex justify-center items-center">
                <h1 class="lalezar-regular text-[7vw] text-[#257DDD] leading-none pt-[1vw]">AUDIT</h1>
              </div>
            </div>
          </div>
          <div class="relative h-[20%] w-[95%] cursor-pointer mr-[0.5vw] border-2 border-black rounded-lg mb-[20vw]">
            <div class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[1vw] translate-y-[1vw]">
            </div>
            <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
              <div class="h-full w-1/5 bg-[#D9D9D9] flex justify-center items-center">
                <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
              </div>
              <div class="h-full w-4/5 flex justify-center items-center">
                <h1 class="lalezar-regular text-[7vw] text-[#257DDD] leading-none pt-[1vw]">PRESALE</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-swap.webp" class="w-full h-auto">
        <div class="absolute bottom-0 left-0 w-full h-[50%] flex justify-center items-start">
          <div class="w-[95%] h-[70%]">
            <div class="h-full w-full bg-[#257DDD] rounded-xl flex justify-center">
              <div class="w-[90%] h-full flex flex-col">
                <div class="w-full h-auto flex justify-between mt-[5vw]">
                  <div class="w-auto h-auto">
                    <h1 class="lalezar-regular text-[7vw] text-white leading-none">Swap</h1>
                  </div>
                  <div class="w-auto h-full flex gap-[1vw] justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-white leading-none pt-[1.5vw]">0.3%</h1>
                    <img src="./assets/swap-setting-ic.png" class="h-[4vw] w-auto">
                  </div>
                </div>
                <div class="relative w-full h-[10vw] mt-[2vw]">
                  <input v-model="searchAddress" type="text" placeholder="Seach address contract" class="w-full h-full rounded-full bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[5vw] pt-[1vw] pl-[5%] pr-[12%]">
                  <div class="absolute top-0 right-0 h-full aspect-square flex justify-center items-center">
                    <img src="./assets/swap-search-ic.webp"
                      class="h-[50%] w-auto transition-all hover:scale-110 active:scale-95">
                  </div>
                </div>
                <div class="relative w-full h-[5vw] mt-[3vw] mb-[3vw] flex justify-between">
                  <h1 class="lalezar-regular text-[4vw] text-white leading-none pt-[0.25vw]">
                    Balance: 0</h1>
                  <div class="h-full w-auto bg-white pl-[3%] pr-[3%] rounded-full flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">
                      MAX</h1>
                  </div>
                </div>
                <div class="relative w-full h-[10vw] bg-white rounded-xl">

                </div>
                <div class="relative w-full h-[7vw] mt-[3vw] mb-[3vw] flex items-center justify-center">
                  <hr class="w-[90%]">
                  <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <img src="./assets/swap-swap-ic.webp"
                      class="h-full w-auto transition-all hover:scale-110 active:scale-95">
                  </div>
                </div>
                <div class="relative w-full h-[10vw] bg-white rounded-xl">

                </div>
                <div class="w-full h-[5vw] flex justify-end items-center">
                  <h1 class="lalezar-regular text-[4vw] text-white leading-none pt-[3vw] mt-[1vw]">
                    1 SOL = 1 SOL</h1>
                </div>
                <div
                  class="relative w-full h-[10vw] bg-[#60adff] rounded-full mt-[6vw] border-2 border-white flex justify-center items-center">
                  <h1 class="lalezar-regular text-[7vw] text-white leading-none pt-[1.5vw]">
                    Connect wallet</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-staking.webp" class="w-full h-auto">
        <div class="absolute bottom-0 left-0 w-full h-[55%] flex justify-center items-center">

          <div class="w-[95%] h-[85%] flex flex-col justify-between">
            <div class="w-full h-[10%] flex">
              <div ref="stakingBtnToggleMobile" @click="stakingWithdrawToggleMobile(1)"
                class="h-full w-1/2 bg-[#257DDD] rounded-xl flex justify-center items-center cursor-pointer transition-all duration-300">
                <h1 class="lalezar-regular text-[7vw] text-white leading-none pt-[1vw] transition-all duration-300">
                  Staking</h1>
              </div>
              <div ref="withdrawBtnToggleMobile" @click="stakingWithdrawToggleMobile(2)"
                class="h-full w-1/2 rounded-xl flex justify-center items-center cursor-pointer transition-all duration-300">
                <h1 class="lalezar-regular text-[7vw] text-[#257DDD] leading-none pt-[1vw] transition-all duration-300">
                  Withdraw</h1>
              </div>
            </div>

            <div ref="stakingFrameMobile"
              class="w-full h-[87%] bg-[#257DDD] rounded-xl flex justify-center transition-all duration-300">
              <div class="w-[90%] h-full flex flex-col">
                <div class="w-full h-auto flex justify-between mt-[5vw]">
                  <div class="w-auto h-auto">
                    <h1 class="lalezar-regular text-[7vw] text-white leading-none">Staking</h1>
                  </div>
                  <div class="w-auto h-full flex gap-[1vw] justify-center items-center">
                    <img src="./assets/token.webp" class="h-[4vw] w-auto">
                    <h1 class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw]">$TRS</h1>
                  </div>
                </div>
                <div class="relative w-full h-[5vw] mt-[3vw] mb-[3vw] flex justify-start">
                  <h1 class="lalezar-regular text-[4vw] text-white leading-none pt-[0.25vw]">
                    Balance: 0</h1>
                </div>
                <div class="relative w-full h-[10vw]">
                  <input type="text" placeholder="0.00" class="w-full h-full rounded-xl bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[5vw] pt-[1vw] pl-[5%] pr-[22%]">
                  <div class="absolute top-0 right-0 w-auto h-full flex justify-center items-center">
                    <div
                      class="h-[80%] w-auto bg-[#257DDD] pl-[3vw] pr-[3vw] rounded-xl transition-all duration-300 flex justify-center items-center mr-[1vw]">
                      <h1 class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw]">
                        MAX</h1>
                    </div>
                  </div>
                </div>
                <div class="relative w-full h-[7vw] mt-[3vw] mb-[3vw] flex justify-between items-center">
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 cursor-pointer">
                    <h1
                      class="lalezar-regular text-[3vw] text-white leading-none pt-[1vw] transition-all duration-300 ">
                      1 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 cursor-pointer">
                    <h1 class="lalezar-regular text-[3vw] text-white leading-none pt-[1vw] transition-all duration-300">
                      3 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 cursor-pointer">
                    <h1 class="lalezar-regular text-[3vw] text-white leading-none pt-[1vw] transition-all duration-300">
                      6 Month</h1>
                  </div>
                  <div
                    class="h-full w-[23%] border-2 border-white rounded-full flex justify-center items-center transition-all duration-300 cursor-pointer">
                    <h1 class="lalezar-regular text-[3vw] text-white leading-none pt-[1vw] transition-all duration-300">
                      12 Month</h1>
                  </div>
                </div>
                <div
                  class="relative w-full h-[10vw] bg-[#60adff] rounded-full border-2 border-white flex justify-center items-center">
                  <h1 class="lalezar-regular text-[7vw] text-white leading-none pt-[1.5vw]">
                    Connect wallet</h1>
                </div>
                <div class="relative w-full h-[5vw] mt-[3vw] mb-[3vw] flex items-center justify-center">
                  <hr class="w-full">
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    Apy</h1>
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    308%</h1>
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    Staking Amount</h1>
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    0 $TRS</h1>
                </div>
                <div class="h-auto w-auto flex justify-between">
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    Profit</h1>
                  <h1
                    class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                    0 $TRS</h1>
                </div>
                <div
                  class="relative w-full h-[10vw] bg-[#60adff] mt-[7vw] rounded-full border-2 border-white flex justify-center items-center">
                  <h1 class="lalezar-regular text-[7vw] text-white leading-none pt-[1.5vw]">
                    Connect wallet</h1>
                </div>
              </div>
            </div>

            <div ref="withdrawFrameMobile" class="w-full h-[87%] transition-all duration-300 opacity-0 hidden">
              <div class="w-full h-[70%] bg-[#257DDD] rounded-xl flex justify-center">
                <div class="h-full w-[90%] flex flex-col">
                  <div class="w-full h-auto flex justify-between mt-[5vw]">
                    <div class="w-auto h-auto">
                      <h1 class="lalezar-regular text-[7vw] text-white leading-none">Withdraw</h1>
                    </div>
                    <div class="w-auto h-full flex gap-[1vw] justify-center items-center">
                      <img src="./assets/token.webp" class="h-[4vw] w-auto">
                      <h1 class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw]">$TRS</h1>
                    </div>
                  </div>
                  <div class="relative w-full h-[5vw] mt-[3vw] mb-[3vw] flex justify-start">
                    <h1 class="lalezar-regular text-[4vw] text-white leading-none pt-[0.25vw]">
                      Balance: 0</h1>
                  </div>
                  <div class="relative w-full h-[10vw]">
                    <input type="text" placeholder="0.00" class="w-full h-full rounded-xl bg-white border-none outline-none text-[#257DDD] 
                  lalezar-regular text-[5vw] pt-[1vw] pl-[5%] pr-[22%]">
                    <div class="absolute top-0 right-0 w-auto h-full flex justify-center items-center">
                      <div
                        class="h-[80%] w-auto bg-[#257DDD] pl-[3vw] pr-[3vw] rounded-xl transition-all duration-300 flex justify-center items-center mr-[1vw]">
                        <h1 class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw]">
                          MAX</h1>
                      </div>
                    </div>
                  </div>
                  <div class="h-auto w-auto flex justify-between mt-[5vw]">
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      Apy</h1>
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      308%</h1>
                  </div>
                  <div class="h-auto w-auto flex justify-between">
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      Staking Amount</h1>
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      0 $TRS</h1>
                  </div>
                  <div class="h-auto w-auto flex justify-between">
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      Profit</h1>
                    <h1
                      class="lalezar-regular text-[5vw] text-white leading-none pt-[1vw] swap-btn-text transition-all duration-300">
                      0 $TRS</h1>
                  </div>
                  <div
                    class="relative w-full h-[10vw] bg-[#60adff] mt-[5vw] rounded-full border-2 border-white flex justify-center items-center">
                    <h1 class="lalezar-regular text-[7vw] text-white leading-none pt-[1.5vw]">
                      Connect wallet</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-roadmap.webp" class="w-full h-auto">
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-partner.jpg" class="w-full h-auto">
        <div class="absolute top-0 left-0 w-full h-full flex justify-center items-center">
          <div class="h-full w-[95%] flex flex-col justify-start items-center">
            <img src="./assets/mobile-partner-title.webp" class="w-full h-auto mt-[15%] mb-[20%]">
            <div class="w-full h-[5%] flex justify-between">
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-pinksale.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">PINKSALE</h1>
                  </div>
                </div>
              </div>
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-dexview.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">DEXVIEW</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full h-[5%] mt-[5%] flex justify-between">
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-raydium.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">RAYDIUM</h1>
                  </div>
                </div>
              </div>
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-dextools.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">DEXTOOLS</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-full h-[5%] mt-[5%] flex justify-between">
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-cmc.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">COIN MC</h1>
                  </div>
                </div>
              </div>
              <div class="relative h-full w-[48%] cursor-pointer transition-all duration-300">
                <div
                  class="absolute top-0 left-0 w-full h-full bg-black rounded-lg translate-x-[0.5vw] translate-y-[0.5vw]">
                </div>
                <div class="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden flex">
                  <div class="h-full w-1/3 bg-[#D9D9D9] flex justify-center items-center">
                    <img src="./assets/partner-cmk.webp" class="h-[80%] w-auto">
                  </div>
                  <div class="h-full w-2/3 flex justify-center items-center">
                    <h1 class="lalezar-regular text-[5vw] text-[#257DDD] leading-none pt-[1vw]">COINGECKO</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative w-full h-auto">
        <img src="./assets/mobile-footer.webp" class="w-full h-auto">
        <div class="absolute bottom-[6%] left-0 w-full h-[7%] flex justify-center items-center gap-[5%]">
          <img src="./assets/footer-telegram.webp" class="h-full w-auto">
          <img src="./assets/footer-telegram.webp" class="h-full w-auto">
          <img src="./assets/footer-x.webp" class="h-full w-auto">
        </div>
      </div>
    </div>

    <div ref="selectToken"
      class="fixed top-0 left-0 w-screen h-svh flex justify-center items-center backdrop-blur-sm z-[9998] transition-all duration-300 opacity-0 hidden">
      <div class="bg-[#257DDD] flex justify-center"
        :class="isMobile ? 'h-full w-full' : 'h-[70%] w-[500px] rounded-xl'">
        <div class="h-full w-[90%] flex flex-col">
          <div class="w-full h-[10%] flex justify-between mt-[5%]">
            <h1 class="montserrat text-white font-bold text-[22px] leading-none">Select a token</h1>
            <img @click="hideSelectToken()" src="./assets/close-large-line.png" class="h-[22px] w-auto cursor-pointer">
          </div>
          <div class="w-full h-[8%] flex justify-betweens">
            <div class="relative w-full h-full">
              <input type="text" placeholder="Search by token or paste address" class="w-full h-full rounded-xl bg-white border-none outline-none text-[#257DDD] 
                  montserrat text-[18px] pl-[5%] pr-[15%]">
              <div class="absolute top-0 right-0 h-full aspect-square flex justify-center items-center">
                <img src="./assets/swap-search-ic.webp"
                  class="h-[50%] w-auto transition-all hover:scale-110 active:scale-95">
              </div>
            </div>
          </div>
          <hr class="w-full mt-[5%] mb-[5%]">
          <div class="w-full h-auto flex justify-between mb-[5%]">
            <h1 class="montserrat text-[#ffffffc5] font-normal text-[16px] leading-none">Token</h1>
            <h1 class="montserrat text-[#ffffffc5] font-normal text-[16px] leading-none">Balance/Address</h1>
          </div>
          <div class="w-full h-[62%] flex flex-col overflow-y-auto overflow-x-hidden scroll">

            <div v-for="token in listTokenSolRaw" :key="token"
              class="w-full h-14 flex transition-all duration-300 hover:bg-[#3c50ff] rounded-xl">
              <div class="h-full w-14 flex justify-center items-center">
                <div class="h-[70%] aspect-square rounded-full overflow-hidden">
                  <img :src="token.logoURI" class="h-full w-auto">
                </div>
              </div>
              <div class="h-full w-[calc(100%-56px)] flex justify-between">
                <div class="h-full w-auto flex flex-col gap-[2%] items-start justify-center">
                  <h1 class="montserrat text-[#fff] font-bold text-[20px] leading-none">{{ token.symbol }}</h1>
                  <h1 class="montserrat text-[#ffffffc5] font-normal text-[13px] leading-none">{{ token.name }}</h1>
                </div>
                <div class="h-full w-auto flex flex-col gap-[2%] items-end justify-center">
                  <h1 class="montserrat text-[#fff] font-bold text-[20px] leading-none mr-[6%]">0</h1>
                  <div class="h-auto w-auto flex justify-center items-end gap-[3%]">
                    <h1 class="montserrat text-[#ffffffc5] font-normal text-[13px] leading-none">{{
                      shortenAddress(token.address) }}</h1>
                    <img src="./assets/copy.png" class="h-[18px] w-auto cursor-pointer">
                    <img src="./assets/open-link.png" class="h-[18px] w-auto mr-[15%] cursor-pointer">
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { Connection, PublicKey } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID, AccountLayout } from '@solana/spl-token';
import axios from 'axios';
// import { Metaplex } from "@metaplex-foundation/js";

export default {
  name: 'App',
  components: {

  },
  setup() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value <= 1025);
    const initialIsMobile = ref(isMobile.value);
    const p1 = ref(null);
    const p2 = ref(null);
    const p3 = ref(null);
    const p4 = ref(null);
    const c1 = ref(null);
    const c2 = ref(null);
    const c3 = ref(null);
    const c4 = ref(null);
    const plane = ref(null);
    const stakingBtnToggle = ref(null);
    const withdrawBtnToggle = ref(null);
    const stakingFrame = ref(null);
    const withdrawFrame = ref(null);
    const banner = ref(null);
    const swap = ref(null);
    const staking = ref(null);
    const roadmap = ref(null);
    const partner = ref(null);
    const withdrawFrameMobile = ref(null);
    const stakingFrameMobile = ref(null);
    const stakingBtnToggleMobile = ref(null);
    const withdrawBtnToggleMobile = ref(null);
    const walletAddress = ref(null);
    const tokens = ref([]);
    const chart = ref(null);
    const searchAddress = ref(null);
    const listTokenSolRaw = ref(null);
    const selectToken = ref(null);
    const rate = ref(null);

    function roadMaphandle() {
      if (!initialIsMobile.value) {
        p2.value.addEventListener('mouseover', () => {
          c2.value.classList.add('-translate-y-[50%]');
          plane.value.classList.add('translate-x-[105%]');
          c1.value.classList.add('translate-y-[50%]');
        });
        p2.value.addEventListener('mouseleave', () => {
          c2.value.classList.remove('-translate-y-[50%]');
          plane.value.classList.remove('translate-x-[105%]');
          c1.value.classList.remove('translate-y-[50%]');
        });
        p3.value.addEventListener('mouseover', () => {
          c3.value.classList.add('-translate-y-[50%]');
          plane.value.classList.add('translate-x-[210%]');
          c1.value.classList.add('translate-y-[50%]');
        });
        p3.value.addEventListener('mouseleave', () => {
          c3.value.classList.remove('-translate-y-[50%]');
          plane.value.classList.remove('translate-x-[210%]');
          c1.value.classList.remove('translate-y-[50%]');
        });
        p4.value.addEventListener('mouseover', () => {
          c4.value.classList.add('-translate-y-[50%]');
          plane.value.classList.add('translate-x-[312%]');
          c1.value.classList.add('translate-y-[50%]');
        });
        p4.value.addEventListener('mouseleave', () => {
          c4.value.classList.remove('-translate-y-[50%]');
          plane.value.classList.remove('translate-x-[312%]');
          c1.value.classList.remove('translate-y-[50%]');
        });
      }
    }

    function stakingWithdrawToggle(type) {
      if (type === 1) {
        withdrawFrame.value.classList.add('opacity-0');
        setTimeout(() => {
          withdrawFrame.value.classList.add('hidden');
          stakingFrame.value.classList.remove('hidden');
          setTimeout(() => {
            stakingFrame.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        stakingBtnToggle.value.classList.add('bg-[#257DDD]')
        stakingBtnToggle.value.querySelector('h1').classList.add('text-white');
        stakingBtnToggle.value.querySelector('h1').classList.remove('text-[#257DDD]');
        withdrawBtnToggle.value.classList.remove('bg-[#257DDD]')
        withdrawBtnToggle.value.querySelector('h1').classList.remove('text-white');
        withdrawBtnToggle.value.querySelector('h1').classList.add('text-[#257DDD]');
      }
      if (type === 2) {
        stakingFrame.value.classList.add('opacity-0');
        setTimeout(() => {
          stakingFrame.value.classList.add('hidden');
          withdrawFrame.value.classList.remove('hidden');
          setTimeout(() => {
            withdrawFrame.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        stakingBtnToggle.value.classList.remove('bg-[#257DDD]')
        stakingBtnToggle.value.querySelector('h1').classList.remove('text-white');
        stakingBtnToggle.value.querySelector('h1').classList.add('text-[#257DDD]');
        withdrawBtnToggle.value.classList.add('bg-[#257DDD]')
        withdrawBtnToggle.value.querySelector('h1').classList.add('text-white');
        withdrawBtnToggle.value.querySelector('h1').classList.remove('text-[#257DDD]');
      }
    }

    function stakingWithdrawToggleMobile(type) {
      if (type === 1) {
        withdrawFrameMobile.value.classList.add('opacity-0');
        setTimeout(() => {
          withdrawFrameMobile.value.classList.add('hidden');
          stakingFrameMobile.value.classList.remove('hidden');
          setTimeout(() => {
            stakingFrameMobile.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        stakingBtnToggleMobile.value.classList.add('bg-[#257DDD]')
        stakingBtnToggleMobile.value.querySelector('h1').classList.add('text-white');
        stakingBtnToggleMobile.value.querySelector('h1').classList.remove('text-[#257DDD]');
        withdrawBtnToggleMobile.value.classList.remove('bg-[#257DDD]')
        withdrawBtnToggleMobile.value.querySelector('h1').classList.remove('text-white');
        withdrawBtnToggleMobile.value.querySelector('h1').classList.add('text-[#257DDD]');
      }
      if (type === 2) {
        stakingFrameMobile.value.classList.add('opacity-0');
        setTimeout(() => {
          stakingFrameMobile.value.classList.add('hidden');
          withdrawFrameMobile.value.classList.remove('hidden');
          setTimeout(() => {
            withdrawFrameMobile.value.classList.remove('opacity-0');
          }, 10);
        }, 300);
        stakingBtnToggleMobile.value.classList.remove('bg-[#257DDD]')
        stakingBtnToggleMobile.value.querySelector('h1').classList.remove('text-white');
        stakingBtnToggleMobile.value.querySelector('h1').classList.add('text-[#257DDD]');
        withdrawBtnToggleMobile.value.classList.add('bg-[#257DDD]')
        withdrawBtnToggleMobile.value.querySelector('h1').classList.add('text-white');
        withdrawBtnToggleMobile.value.querySelector('h1').classList.remove('text-[#257DDD]');
      }
    }

    function scrollToElement(element, position = 'center') {
      if (!element) return;

      const positionOptions = {
        'start': 'start',
        'center': 'center',
        'end': 'end',
        'nearest': 'nearest'
      };

      const scrollPosition = positionOptions[position] || 'center';

      element.scrollIntoView({
        behavior: 'smooth',
        block: scrollPosition,
        inline: scrollPosition
      });
    }

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      if (isMobile.value !== initialIsMobile.value) {
        window.location.reload(true);
      }
    };

    function installPhantomWallet() {
      window.open('https://phantom.app/', '_blank');
    }

    const connectWallet = async () => {
      if (walletAddress.value) {
        disconnect();
        return;
      }
      if ('phantom' in window) {
        const provider = window.phantom?.solana;
        if (provider?.isPhantom) {
          try {
            const resp = await provider.connect();
            walletAddress.value = resp.publicKey.toString();
            getListToken();
          } catch (error) {
            console.log(error)
          }
          return provider;
        }
      } else {
        installPhantomWallet();
      }
    };

    async function getListToken() {
      if (walletAddress.value) {
        const connection = new Connection("https://api.devnet.solana.com", "confirmed");
        const publicKey = new PublicKey('A1TMhSGzQxMr1TboBKtgixKz1sS6REASMxPo1qsyTSJd');
        try {
          const tokenAccounts = await connection.getTokenAccountsByOwner(publicKey, {
            programId: TOKEN_PROGRAM_ID
          });
          const tokensList = tokenAccounts.value.map((tokenAccount) => {
            return AccountLayout.decode(tokenAccount.account.data)
          });
          tokens.value = tokensList;
        } catch (error) {
          console.error(error);
        }
      }
    }

    function shortenAddress(address) {
      if (address && address.length > 0) {
        const start = address.substring(0, 4);
        const end = address.substring(address.length - 4);
        return `${start}...${end}`;
      }
    }

    async function disconnect() {
      walletAddress.value = null;
      await window.solana.disconnect();
    }

    // async function getTokenMetadata(connection, mintPublicKey) {
    //   try {
    //     const metaplex = Metaplex.make(connection);

    //     const metadataAccount = metaplex
    //       .nfts()
    //       .pdas()
    //       .metadata({ mint: mintPublicKey });

    //     const metadataAccountInfo = await connection.getAccountInfo(metadataAccount);

    //     if (metadataAccountInfo) {
    //       const token = await metaplex.nfts().findByMint({ mintAddress: mintPublicKey });
    //       return {
    //         name: token.name,
    //         symbol: token.symbol,
    //       }
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    async function swapToken() {
      console.log('swap');
    }

    function showChart() {
      console.log(searchAddress.value)
      if (!searchAddress.value) return;
      chart.value.src = `https://dexscreener.com/solana/${searchAddress.value}?embed=1&theme=dark&trades=1&info=0`;
    }

    async function getTokenList() {
      try {
        const response = await axios.get('https://tokens.jup.ag/tokens?tags=verified');
        listTokenSolRaw.value = response.data;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    }

    async function getPriceRate(token, vsToken) {
      try {
        const response = await axios.get(`https://api.jup.ag/price/v2?ids=${token.address}&vsToken=${vsToken.address}`);
        rate.value = `1 ${token.symbol} = ${Number(response.data.data[token.address].price).toFixed(2)} ${vsToken.symbol}`
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    }

    function hideSelectToken() {
      selectToken.value.classList.add('opacity-0');
      setTimeout(() => {
        selectToken.value.classList.add('hidden');
      }, 300);
    }

    function showSelectToken(position) {
      console.log(position);
      selectToken.value.classList.remove('hidden');
      setTimeout(() => {
        selectToken.value.classList.remove('opacity-0');
      }, 10);
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize);
      roadMaphandle();
      connectWallet();
      getTokenList();
      getPriceRate({
        address: 'So11111111111111111111111111111111111111112',
        symbol: 'SOL'
      }, {
        address: 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
        symbol: 'USDC'
      })
    });

    return {
      p1, p2, p3, p4, c1, c2, c3, c4, plane, stakingBtnToggle,
      withdrawBtnToggle, stakingFrame, withdrawFrame, stakingWithdrawToggle,
      scrollToElement, banner, swap, staking, roadmap, partner, windowWidth, isMobile, initialIsMobile,
      withdrawFrameMobile, stakingFrameMobile, stakingBtnToggleMobile, withdrawBtnToggleMobile,
      stakingWithdrawToggleMobile, connectWallet, walletAddress, tokens, shortenAddress, disconnect,
      swapToken, chart, searchAddress, showChart, listTokenSolRaw, selectToken, hideSelectToken,
      showSelectToken, rate
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lalezar&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.scroll::-webkit-scrollbar {
  width: 5px;
}

.scroll::-webkit-scrollbar-track {
  background: transparent;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 9999px;
}


* {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html,
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, Opera */
}

.box-shadow {
  box-shadow: 0 0 10px #000;
}

.lalezar-regular {
  font-family: "Lalezar", system-ui;
  font-weight: 400;
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  height: calc(100% + 38px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
}

.swap-btn:hover,
.staking-month-btn:hover {
  background-color: #fff;
}

.swap-btn:hover .swap-btn-text {
  color: #60adff;
}

.staking-month-btn:hover .staking-month-btn-text {
  color: #257DDD;
}
</style>
